import React, { useEffect, useState } from "react";
import NavBarConnexion from "../NavBarConnexion";
import Home from "../Pages/Home";
import Footer from "../Footer";




const GestionHome = () => {

 

  return (
    <>
      <NavBarConnexion />
      <div className="containersearch">
        <div>
          <h5 className="igt" >
            {" "}
            PRECVADI : Plateforme de renforcement des chaines de valeurs de
            données infranationales{" "}
          </h5>
        </div>
      </div>
      
      <Home />
      <Footer />
    </>
  );
};

export default GestionHome;
