import React, { useState } from 'react';
import "../../css/home.css";
import "../../css/button.css";
import MonCarrousel from './MonCarrousel';

// import { MapContainer, TileLayer, useMap,Marker,Popup } from 'leaflet'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Button } from "react-bootstrap";
import DemoCarousel from "./Carousel";
// import Demographie from "./DemographicData";
import Newsletter from "./Newsletter";
// import Maps from "./Maps";
// import MapsSante from "./MapsSante";
import Partenaires from './NosPartenaires';


const educicon = require("../images/educicon.png");
const santeicon = require("../images/educsante.png");




export const Home = () => {


  const [isEducation, SetEducation] = React.useState(true);
  const [isSante, SetSanté] = React.useState(false);
  const HandleEducation = () => {
    SetEducation(true);
    SetSanté(false);
    /* console.log("test education"); */
  };
  const HandleSante = () => {
    SetSanté(true);
    SetEducation(false);
    /* console.log("test sante "); */
  };




  const [selectedOption, setSelectedOption] = useState('option1');

  
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);

  };


  const Access = () => {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyItems: "center",
        }}
      >
        <iframe
          title="DashboardIGT"
          width="100%"
          height="100%"
          src="https://app.powerbi.com/view?r=eyJrIjoiZWM0ZDhhODYtOGQzOS00ZTNjLWIwMzktMjBmMmYxMzQ4ZDlmIiwidCI6ImJmZTY0YzY0LWIwY2UtNDJiZC04MDlhLWE4ODlhMjJhZGQwOSJ9" 
         
          frameborder="0"
          allowFullScreen="true"
        ></iframe>
      </div>
    );
  };

  const Env = () => {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyItems: "center",
        }}
      >
        <iframe
          title="DashboardIGT"
          width="100%"
          height="100%"
          src="https://app.powerbi.com/view?r=eyJrIjoiNjUzMzJmZTEtNTNlOS00NTlhLWI5YjItYWZiYmE0OWUyNmI4IiwidCI6ImJmZTY0YzY0LWIwY2UtNDJiZC04MDlhLWE4ODlhMjJhZGQwOSJ9"
          frameborder="0"
          allowFullScreen="true"
        ></iframe>
      </div>
    );
  };

  const Performance = () => {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyItems: "center",
        }}
      >
        <iframe
          title="DashboardIGT"
          width="100%"
          height="100%"
          src="https://app.powerbi.com/view?r=eyJrIjoiYmIyYTQ1ZDUtZGU3Ny00ZmFhLTkyODYtYjAyOTNkYmNkNDQ3IiwidCI6ImJmZTY0YzY0LWIwY2UtNDJiZC04MDlhLWE4ODlhMjJhZGQwOSJ9"
         
          frameborder="0"
          allowFullScreen="true"
        ></iframe>
      </div>
    );
  };

  const Gouvernance = () => {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyItems: "center",
        }}
      >
        <iframe
          title="DashboardIGT"
          width="100%"
          height="100%"
          src="https://app.powerbi.com/view?r=eyJrIjoiNGYwZjEwMjQtNzVjMC00MGFmLWI2MTUtMTJjNjY5MGFlZTNmIiwidCI6ImJmZTY0YzY0LWIwY2UtNDJiZC04MDlhLWE4ODlhMjJhZGQwOSJ9"
         
          frameborder="0"
          allowFullScreen="true"
        ></iframe>
      </div>
    );
  };

  const CommuneDashbaord = () => {
    return (
      <>
    
     {/*} <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyItems: "center",
        }}
      >
        <iframe
          title="DashboardIGT"
          width="2000"
          height="650"
          src="https://app.powerbi.com/view?r=eyJrIjoiNGUzZjc4ODctOTIxOS00NDBkLWFmMWMtODk4ZTNmODIyZmViIiwidCI6ImJmZTY0YzY0LWIwY2UtNDJiZC04MDlhLWE4ODlhMjJhZGQwOSJ9"
          frameborder="0"
          allowFullScreen="true"
        ></iframe>
        
        
      </div>*/}
      <div className='communeDashboard'>
        {/*Nouveau pour les autres sites montrolland et thiadiaye*/}
        <div style={{display:"flex",flexDirection:"column",width:"25%",border:"1px solid #208001",justifyContent:'space-beetween'}} >
        <h4 className='sous-titre'>Sous-domaine</h4>
        <label className='labelRadio'>
        <input
        className='inputRadio'
        style={{marginTop:"2.5rem"}}
          type="radio"
          value="option1"
          checked={selectedOption === 'option1'}
          onChange={handleOptionChange}
        />
        ACCES ET PARTICIPATION
      </label >

      <label className='labelRadio'>
        <input
        className='inputRadio'
          type="radio"
          value="option2"
          checked={selectedOption === 'option2'}
          onChange={handleOptionChange}
        />
        ENVIRONNEMENT SCOLAIRE
      </label>

      <label className='labelRadio'>
        <input
        className='inputRadio'
          type="radio"
          value="option3"
          checked={selectedOption === 'option3'}
          onChange={handleOptionChange}
        />
        PERFORMANCE
      </label>

      <label className='labelRadio'>
        <input
        className='inputRadio'
          type="radio"
          value="option4"
          checked={selectedOption === 'option4'}
          onChange={handleOptionChange}
        />
        GOUVERNANCE
      </label>

        </div>
        <div style={{width:"75%"}}>
          {selectedOption == 'option1' && <Access/>}
          {selectedOption == 'option2' && <Env/>}
          {selectedOption == 'option3' && <Performance/>}
          {selectedOption == 'option4' && <Gouvernance/>}
        </div>
      </div>
    </>
    );
  };

  const TableauBordEducation = () => {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyItems: "center",
        }}
      >
        <iframe
          title="DashboardIGT"
          width="100%"
          height="100%"
          src="https://app.powerbi.com/view?r=eyJrIjoiYzZlNDBmNGYtYzZkMi00ZDgwLTg5NDgtYTU3MWQzMjYzNjFiIiwidCI6ImJmZTY0YzY0LWIwY2UtNDJiZC04MDlhLWE4ODlhMjJhZGQwOSJ9"
          // src="https://app.powerbi.com/view?r=eyJrIjoiODA5NjUwOGQtYjZkNi00YmNjLTg0ZTItN2E5NGY5M2E1Mzg0IiwidCI6ImJmZTY0YzY0LWIwY2UtNDJiZC04MDlhLWE4ODlhMjJhZGQwOSJ9"
          frameborder="0"
          allowFullScreen="true"
        ></iframe>
      </div>
    );
  };
  const TableauBordSante = () => {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyItems: "center",
        }}
      >
        <iframe
          title="DashboardIGT"
          width="100%"
          height="100%"
          //SRC SANTE BOULEL TEMPORAIRE
          src="https://app.powerbi.com/view?r=eyJrIjoiZWVjMWQ0MzktNTZlNS00N2QyLWJhOWItNTYxYTkzYTIzNzMxIiwidCI6ImJmZTY0YzY0LWIwY2UtNDJiZC04MDlhLWE4ODlhMjJhZGQwOSJ9"
          
          frameborder="0"
          allowFullScreen="true"
        ></iframe>
      </div>
    );
  };
  // const CarteSIGEducation = () => {
  //   return (
  //     <div
  //             style={{
  //               width: "100%",
  //               height: "100%",
  //               display: "flex",
  //               justifyItems: "center",
  //             }}
  //           >

  //             <iframe
  //               title="DashboardIGT"
  //               width="2000"
  //               height="650"
  //               //SRC SANTE BOULEL TEMPORAIRE
  //               src="https://app.powerbi.com/view?r=eyJrIjoiMjdlMDU4YTEtZTE1MC00NjY4LTkyZTMtMGMzM2ZmYmY5MGM0IiwidCI6ImJmZTY0YzY0LWIwY2UtNDJiZC04MDlhLWE4ODlhMjJhZGQwOSJ9"
  //               frameborder="0"
  //               allowFullScreen="true"
  //             ></iframe>

  //           </div>
  //   )

  // };
  // const CarteSIGSante = () => {
  //   return (
  //     <div
  //             style={{
  //               width: "100%",
  //               height: "100%",
  //               display: "flex",
  //               justifyItems: "center",
  //             }}
  //           >

  //             <iframe
  //               title="DashboardIGT"
  //               width="2000"
  //               height="650"
  //               src="https://app.powerbi.com/view?r=eyJrIjoiMWY1YjE1YTItYjZkMy00NjUyLTgxYTctZjg0OWFjZDVmZjQxIiwidCI6ImJmZTY0YzY0LWIwY2UtNDJiZC04MDlhLWE4ODlhMjJhZGQwOSJ9"
  //               frameborder="0"
  //               allowFullScreen="true"
  //             ></iframe>

  //           </div>
  //   )

  // };
  return (
    <div>
      <div className="main">
        {/* <div className="heading"> */}

        <div className="carouselcontain">
        <div className="cardDomain">
            <div>
              {/* <h2 style={{color:"white",textDecoration:"underline"}}  > Commune de Boulel </h2>  */}
              <p className='carous'>
                {" "}
               {/* Cette plateforme met ensemble des données clés et informations
                essentielles sur les différents axes prioritaires de
                développement au niveau de la commune de Boulel. Ce système de
                données fédère les besoins en données des acteurs clés
                intervenant dans le développement communal. Il s’agira pour
                cette phase de données concernant la santé, l’éducation.*/}
          <MonCarrousel />
                
              </p>
            </div>
          </div>
          <div className="carouseldiv">
            {/* <img
                alt="precvadi"
                src={thiadiaye}
                className="carouselimg"
                fluid
                rounded
              /> 
            <DemoCarousel />*/}
            <iframe title="homePageBoulel" style={{height:`100%`, width:`100%`}} 
            src="https://app.powerbi.com/view?r=eyJrIjoiOTQzZmJlOWQtN2YyNS00YzA3LTlmZjQtMzhjOWYyZmQ2MDI1IiwidCI6ImJmZTY0YzY0LWIwY2UtNDJiZC04MDlhLWE4ODlhMjJhZGQwOSJ9" 
            frameborder="0" allowFullScreen="true"></iframe>
            
          </div>
          
        </div>

        <div>
          {/* <Container className="shadow-lg p-1 mb-1 bg-body ">
            <Row sm={12} className=" px-4 my-5">    
            <Col sm={12}>  */}
          <div className='contain'>
           <span className='span1'>Atteinte</span> <span className='span2' style={{backgroundColor:"#356C2A"}} ></span>&nbsp;&nbsp;
           <span className='span1'>En partie atteinte</span> <span className='span2' style={{backgroundColor:"#EEFF00"}} ></span>&nbsp;&nbsp;
           <span className='span1'>Non atteinte</span> <span className='span2' style={{backgroundColor:"#D70012"}} ></span>&nbsp;&nbsp;
           <span className='span1'>Donnée manquante ou valeur nulle</span> <span className='span2' style={{backgroundColor:"#B3B3B3"}} ></span>
          </div>
          {/* <div className="button">  
          <div className="button11" > </div>
         <div className="button1"  > <button className="buttondomain" style={{ backgroundColor: isEducation ? "#316685" : "#7b9bad" }}  type="button"  onClick={HandleEducation}>Education</button> </div>
         <div className="button2"  > <button className="buttondomain" type="button" style={{ backgroundColor: isSante ? "#316685" : "#7b9bad" }}  onClick={HandleSante}>Santé</button> </div>
         <div className="button12" >  </div>
          </div>  */}
          {/* {!isEducation && <TableauBordEducation/> } */}
          <div className='w-100 d-flex justify-content-center align-items-center'>
            <div className='spaced-div commune'>
              <CommuneDashbaord />
            </div>
          </div>  
        </div>

        <div className="communeinfo">
          <div className="spaced-div arrow">
            <h1 className='gros-titre'>Système d'information géographique</h1>
          </div>
          <div className="cardrowdomain">
            <div className="card">
              <Button
                className='btnIcon'
                style={{
                  backgroundColor: isEducation ? "#316685" : "#7b9bad",
                }}
                onClick={HandleEducation}
              >
                <img
                  alt="precvadi"
                  src={educicon}
                  className="carouselicon"
                  fluid
                  rounded
                />
                <h5 className='titreES'>Education</h5>
              </Button>
            </div>
            <div className="card">
              <Button
              className='btnIcon'
                style={{
                  backgroundColor: isSante ? "#316685" : "#7b9bad",
                }}
                type="button"
                onClick={HandleSante}
              >
                <img
                  alt="precvadi"
                  src={santeicon}
                  className="carouselicon"
                  fluid
                  rounded
                />
                <h5 className='titreES'>Sante</h5>
              </Button>
              </div>
              
          </div>
          <div className='spaced-div tabES' style={{width:`91.5%`, marginTop: `1rem`}}>
            {isEducation && <TableauBordEducation />}
            {isSante && <TableauBordSante />}
          </div>
       {/*   <TableauBordEducation />*/}
        </div>
        {/* <Demographie/>  */}
        <div style={{width:`100%`, display:`flex`, justifyContent:`center`, alignItems:`center`, marginBottom:`5rem`, marginTop:`5rem`}}>
          <div className='spaced-div' style={{width:`91.5%`, display:`flex`, justifyContent:`center`, alignItems:`center`, gap:`1rem`}}>
            <div className='letter' style={{border:`1px solid #208001`}}>
              <div  style={{width:`100%`}}>
                  <h4 className='sous-titre1'>Nos contacts</h4>
              </div>
              <div className='d-flex justify-content-left align-items-center mt-5 num'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16" className='icon'>
                  <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"/>
                </svg>
                <h4 className='contact'>&nbsp;&nbsp;&nbsp;&nbsp;(+221) 77 611 64 32</h4>
              </div>
              <div className='d-flex justify-content-left align-items-center mt-5 num'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-envelope-fill" viewBox="0 0 16 16" className='icon'>
                  <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586zm3.436-.586L16 11.801V4.697z"/>
                </svg>
                <h4 className='contact'>&nbsp;&nbsp;&nbsp;&nbsp;precvadiboulel@gmail.com</h4>
              </div>
              <div className='d-flex justify-content-left align-items-center mt-5 num'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16" className='icon'>
                  <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6"/>
                </svg>
                <h4 className='contact'>&nbsp;&nbsp;&nbsp;&nbsp;Departement de Kaffrine</h4>
              </div>
            </div>
            <div className='letter'>
              <Newsletter />
            </div>
          </div>
        </div>
        <div style={{width:`100%`, display:`flex`, justifyContent:`center`, alignItems:`center`}}>
          <div className='spaced-div' style={{width:`91.5%`, display:`flex`, flexDirection:`column`, justifyContent:`center`, alignItems:`center`, gap:`1rem`}}>
            <div className="arrow" style={{width:`100%`}}>
                <h1 className='gros-titre'>Nos partenaires</h1>
            </div>
            {/* <div style={{width:`50%`, height:`54.5vh`, border:`1px solid`}}>
              
            </div> */}
            <div className='spaced-div partenaire'>
              
              <Partenaires />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Home;
