import React, { useEffect, useState } from "react";
import NavBar from "../NavBar";
import Home from "./Home";
import Footer from "../Footer";
import '../../css/Acceuil.css'
//import Newsletter from "./Components/Newsletter";

const Acceuil = () => {



  return (
    <>
      <NavBar />
      <div className="containersearch">
        <div>
          <h5 className="igt" >
            {" "}
            PRECVADI : Plateforme de renforcement des chaines de valeurs de
            données infranationales{" "}
          </h5>
        </div>
      </div>

      <Home />
     {/* <Newsletter/> */}
     <Footer />
      
    </>
  );
};

export default Acceuil;
