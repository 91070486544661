import React, { useState } from "react";
import { NavLink } from "react-router-dom";
//import NavDropdown from "react-bootstrap/NavDropdown";
// import Footer from "./Footer";
import "./NavBar.css";


const logo = require ("../components/images/logo_boulel.jpg");

function NavBar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  return (
    <>
      <nav className="navbar">
          <div className="logo">
          <NavLink exact to="/">
           {/* <FontAwesomeIcon icon={faAlignJustify} /> */}
            <img className="nav-logo" title="BOULEL" src={logo} alt="logo boulel"/>
            {/* <p style={{fontSize: `1.2rem`, fontWeight: `bold`}}>BOULEL</p>  */}
          </NavLink>
          </div>
        <div className="nav-container">
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <NavLink
                exact
                to="/"
                activeClassName="active"
                className="nav-links fw-bold"
                onClick={handleClick}
              >
                Accueil              </NavLink>
            </li>
            
            <li className="nav-item">
              <NavLink
                exact
                to="/indicateur"
                activeClassName="active"
                className="nav-links fw-bold"
                onClick={handleClick}
              >
                Indicateur
              </NavLink>
            </li>
              
            <li className="nav-item">
              <NavLink
                exact
                to="/commune"
                activeClassName="active"
                className="nav-links fw-bold"
                onClick={handleClick}
              >
                Ressources
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                exact
                to="/blog"
                activeClassName="active"
                className="nav-links fw-bold"
                onClick={handleClick}
              >
                Blog
              </NavLink>
            </li>

            <li className="nav-item conn" /* style={{marginLeft:"15rem"}} */>
              <NavLink
                exact
                to="/signin"
                activeClassName="active"
                className="nav-links connection fw-bold"
                onClick={handleClick}
                /* style={{display:"flex", justifyContent:"center", alignItems:"center", gap:"0.5rem", backgroundColor:"#208001", borderRadius:"1rem", border:"0.5px solid"}} */
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-person-fill" viewBox="0 0 16 16" className="icones">
                  <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
                </svg>
                Se Connecter
              </NavLink>
            </li>
          </ul>
          <div className="nav-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
          </div>
        </div>
      </nav>
    </>
  );
}

export default NavBar;
